<template>
  <v-container>
    <v-row>
      <v-card
        class="mx-auto my-12"
        max-width="574"
      >
        <v-card-title>PAINÉIS</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete 
                  label="PAINÉIS"
                  v-model="config"
                  :items="lista_6"
                  outline 
                  @change="conf()"
                  return-object
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
      </v-card>
    </v-row>
 
    <v-row>
      
      <Painel 
        :chamadas="senhas" 
        :totem="totem"
        :painel="config"
      />
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import AppEtlService from '@/service/AppEtlService'
const api = new AppEtlService();
export default {  
  name:'Lista',
  components:{
    Painel:()=>import('./Painel.vue'),
  },
  data:()=>({
    empresa:{},
    totem:'',
    config:{},
    senhas:[]
  }),
  methods:{
    async get_empresas(){
      this.loading = true
      return await api
      .get_query('contas/usuarios/')
      .then( response => { this.$store.dispatch('conta', response.data);})
      .catch( error => { console.log(error)})
      .finally( ()=>{ this.loading = false})
    },
    conf(){
      console.log(this.config) 
    },
    /*
    empresas(){
      this.$store.dispatch('lista',{
        method: 'get',
        url:`/etl/empresas/`,
        instancia:'lista_3_array'
      })
    },*/
    salvaPreferencias(){
      //localStorage.setItem('locais_tmp',JSON.stringify(this.locais))
      //localStorage.setItem('ultimas_tmp',this.totem)
      //localStorage.setItem('empresa_tmp',this.empresa.value)
    },
    painel(e){
      this.$store.dispatch('lista',{
        method: 'get',
        url:`/etl/maquinas?maquina=${e}`,
        instancia:'lista_4'
      })
    },
    lista_maquinas(e){
      this.painel(e)
      this.$store.dispatch('locais',e.maquina) 
    },
    add(){
      this.$store.dispatch('increment_local',{})
    },
    remove(val){
      this.$store.dispatch('decrement_local',val,1)
    }
  },
  computed:{
    ...mapGetters(['lista_3_array','lista_4','locais','lista_6']),
  },
  mounted(){
    //this.$store.dispatch('increment_local',{})
    this.$store.dispatch('lista',{
      method:'get',
      url:'/paineis_senhas/paineis/',
      instancia:'lista_6'
    })
    // this.empresas()
    console.log('undidae', this.config)

  },
}
</script>